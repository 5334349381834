<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="mb-2 align-center">
          <!-- <v-card-title class="d-flex align-center justify-center py-7"> -->

          <v-img
            :src="require('@/assets/images/logos/efectivo-ya-png.png')"
            max-height="100%"
            max-width="100%"
            alt="Efectivo Ya!"
            class="me-3 "
          ></v-img>

          <!-- title -->
          <v-card-text class="align-center">
            <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">
              Bienvenido a Efectivo Ya!
            </p>
            <p class="mb-2 align-center text-center">
              Por favor inicie sesión en su cuenta para operar
            </p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="user"
                outlined
                :rules="[Validaciones.requerido]"
                label="Usuario"
                placeholder="Ingrese su Usuario"
                class="mb-3"
                @keyup.enter="IniciarSesionEncode"
              ></v-text-field>

              <v-text-field
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                :rules="[Validaciones.requerido, Validaciones.min]"
                label="Password"
                counter
                placeholder="Ingrese su Password"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
                @keyup.enter="IniciarSesionEncode"
              ></v-text-field>

              <div class="d-flex align-center justify-space-between flex-wrap">
                <v-checkbox
                  label="Recuérdame"
                  hide-details
                  class="me-3 mt-1"
                >
                </v-checkbox>

                <!-- forgot link -->
                <a
                  href="javascript:void(0)"
                  class="mt-1"
                >

                  ¿Olvidó su contraseña?

                </a>
              </div>

              <v-btn
                block
                color="primary"
                class="mt-6"
                :loading="loadingButton"
                @click="IniciarSesionEncode()"
              >
                Iniciar Sesión
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card-title>
      </v-card>
    </div>
    <v-snackbar
      v-model="Showsnackbar"
      top
      :color="colorSnack"
    >
      {{ mensajeSnack }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="Showsnackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-img
      class="auth-tree-3"
      width="377"
      height="328"
      src="@/assets/images/misc/ilustracion-dinero.png"
    ></v-img>
    <SnackBar />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import axios from 'axios'
import sha512 from 'js-sha512'
import SnackBar from '../../components/base/SnackGlobal.vue'

export default {
  components: { SnackBar },
  icons: {
    mdiEyeOutline,
    mdiEyeOffOutline,
  },
  data: () => ({
    isPasswordVisible: '',
    user: '',
    password: '',
    mensajeSnack: '',
    Showsnackbar: false,
    colorSnack: 'success',
    loadingButton: false,
    valid: false,
    correoElectronico: '',
    show1: false,
    respuesta: null,
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
    Validaciones: {
      requerido: v => !!v || 'Campo Requerido',
      emailRules:
        v => /.+@+.+.com+/.test(v) || 'E-mail debe ser valido',
      min: v => v.length >= 6 || 'Minimo 6 caracteres',

    },
  }),

  methods: {
    async IniciarSesionEncode() {
    //  this.$router.push({ name: 'reportpaymentdocument', params: { documentnumber:'2023112018' } })
      if (this.$refs.form.validate()) {
        this.loadingButton = true
        this.url = `${this.$store.getters.urlBase}/api/Account`
        const data = {
          userName: this.user,
          password: sha512(this.password),
        }
        this.loadingButton = true
        
        await axios
          .post(this.url, data)
          .then(response => (this.respuesta = response))
          .catch(error => {
            if (error.response.data) {
              this.mensajeSnack = `Error: ${error.response.data}`
            } else if (error.message) {
              this.mensajeSnack = `Error: ${error.message}`
            } else {
              this.mensajeSnack = 'Error de Conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,
            })
          })
          .finally(e => (this.loadingButton = false))

        if (this.respuesta.status === 200) {
          const actualUser = {
            user_personid: this.respuesta.data.personId,
            user_name: this.respuesta.data.userName.toLowerCase(),
            user_rol: this.respuesta.data.rol,
            name: this.respuesta.data.name,
            token: this.respuesta.data.token,
            expiration: this.respuesta.data.expiration,
            cash_register: this.respuesta.data.cashregister,
          }
          this.$store.dispatch('agregarUsuarioAction', actualUser)
          const cashRegister = {
            // cash_register: this.respuesta.data.cashregister,
            id: this.respuesta.data.cashregister.id,
            date: this.respuesta.data.cashregister.date,
            amount: this.respuesta.data.cashregister.amount,
            isOpen: this.respuesta.data.cashregister.isOpen,

          }
          this.$store.dispatch('agregarCajaAction', cashRegister)
          this.$router.push('/dashboard')
        }
      }
    },

  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
